// import React from 'react';

// const Event1Details = () => {
//     return (
//         <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif' }}>
//             <h1>Global Refining & Petrochemicals Congress</h1>
//             <h3>8th Edition 2024</h3>
//             <p><strong>Location:</strong> Hotel Le Meridien, New Delhi, India</p>
//             <p><strong>Start:</strong> 9:00 AM, 27th June 2024</p>
//             <p><strong>End:</strong> 5:30 PM, 28th June 2024</p>
//             <p><strong>Contact:</strong> Mr. Sunny Mehta</p>
//             <p>Email: <a href="mailto:Sunny@itenmedia.in">Sunny@itenmedia.in</a></p>
//             <p>Phone: 01143013474, 09711433168</p>
//             <p>Event Website: <a href="http://www.refpet.com" target="_blank" rel="noopener noreferrer">www.refpet.com</a></p>
//             <div>
//                 <img src="path_to_left_side_image.jpg" alt="Event Logo" style={{ width: '335px', height: '270px', float: 'left' }} />
//                 <div style={{ float: 'right', width: '400px', padding: '10px' }}>
//                     <ul>
//                         <li>Smart Manufacturing</li>
//                         <li>Renewable Fuels</li>
//                         <li>Sustainability</li>
//                         <li>Commercial Opportunities</li>
//                     </ul>
//                 </div>
//                 <div style={{ clear: 'both', marginTop: '300px' }}>
//                     <p>
//                         The Global Refining & Petrochemicals Congress (GRPC) is a leading international forum for policy makers, asset owners, licensors, contractors, technology majors, research organisations & equipment suppliers to explore next generation downstream opportunities whilst collaborating on emerging & future risks across industry markets.
//                     </p>
//                     <p>
//                         The conference builds upon the existing knowledge of experts & industry stakeholders defining critical areas of collaboration including smart manufacturing, renewable fuels, sustainability, commercial opportunities, regulatory compliances, operational excellence, process technology innovations, catalyst developments & complex integrations whilst highlighting the global & regional market trends.
//                     </p>
//                     <p>
//                         It also serves as a niche-networking forum, providing an opportunity to meet and interact with the leading experts, technology partners, researchers, colleagues and friends from academia.
//                     </p>
//                     <p>
//                         Attracting a diverse and eclectic mix of speakers, delegates, exhibitors and attendees, GRPC facilitates the exchange of ideas and discussions on pertinent energy-related issues, while simultaneously meeting the strategic objectives of the industry, making it the most important event for those involved in the refining and processing of hydrocarbons.
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Event1Details;



// EventDetails.js
import React from 'react';
import './Event1Details.css'; // Make sure to import the CSS file for styles
import eventimage from '../../../assets/events/India Chem 2024_400 x 200 pixels Logo 2.jpg'
const Event4Details = () => {
    return (
        <div className="event-details">
            <h1>India Chem 2024 </h1>
            <div className="event-header">
                <img src={eventimage} alt="Event Logo" className="event-image" />
                <div style={{fontFamily : 'Helvitica' , fontSize : 'medium'}}>
                <p>
                Flagship event, focuses on Chemicals, Petrochemicals and Chemical Technology, jointly organized by Ministry of Chemicals and Fertilizers, Government of India and FICCI after every 2 years
                              </p>
                    <p><strong>Location:</strong>  Bombay Exhibition Centre, Mumbai, India</p>
                    <p><strong>Start:</strong>  17th October 2024, 10:00 AM</p>
                    <p><strong>End:</strong> 19th October 2024, 6:00PM</p>
                    <p><strong>Contact:</strong>Ishant Rai</p>
                    <p><strong>Email:</strong> <a href="mailto:ishant.rai@ficci.com">ishant.rai@ficci.com</a></p>
                    <p><strong>Phone:</strong>+91 7840870556</p>
                    <p><strong>Website:</strong> <a href="www.indiachem.in" target="_blank" rel="noopener noreferrer">www.indiachem.in</a></p>
                </div>
            </div>
            <div className="event-description">

                    <p>
                    Department of Chemicals & Petrochemicals, Ministry of Chemicals & Fertilizers, Government of India, jointly with FICCI is organising the 13th Edition of “India Chem 2024” from 17th - 19th October 2024 at Bombay Exhibition Centre, Mumbai, India. India Chem, the flagship event of the Department, is one of the largest composite events of the industry in the Asia-Pacific region and comprises of an International Exhibition and various conferences. India Chem 2024 will showcase tremendous potential and supportive government policy for sustainable growth in the sector and will be a single platform for investors, both domestic and international and other stakeholders to interact and forge alliances, thereby providing immense potential for trade and investment, in a mutually beneficial way. The concurrent sessions as part of conference includes Global CEOs Round Table, ministerial plenary as well as conclaves on different industry segments (e.g., Chemicals, Petrochemical, Agrochemical Industry, Process and machinery) and regional exchanges between India and the counties.
                                         </p>
                   

            </div>
        </div>
    );
}

export default Event4Details;
