import React from "react";
import "./newsletter.css";
import { Link } from "react-router-dom";

const Newsletters = () => {
  return (
    <div>
      {/* August 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          August 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/August/02-August-2024" target="_blank">
              <div className="link-text">02-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/09-August-2024" target="_blank">
              <div className="link-text">09-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/16-August-2024" target="_blank">
              <div className="link-text">16-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/23-August-2024" target="_blank">
              <div className="link-text">23-August-2024</div>
            </Link>
          </div>
        </div>
      </div>
      {/* July 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          July 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/July/12-July-2024" target="_blank">
              <div className="link-text">12-July-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/July/26-July-2024" target="_blank">
              <div className="link-text">26-July-2024</div>
            </Link>
          </div>
        </div>
      </div>

      {/* June 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          June 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/June/07-June-2024" target="_blank">
              <div className="link-text">07-June-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/June/14-June-2024" target="_blank">
              <div className="link-text">14-June-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/June/27-June-2024" target="_blank">
              <div className="link-text">27-June-2024</div>
            </Link>
          </div>
        </div>
      </div>

      {/* May 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          May 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/May/05-May-2024" target="_blank">
              <div className="link-text">05-May-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/May/17-May-2024" target="_blank">
              <div className="link-text">17-May-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/May/24-May-2024" target="_blank">
              <div className="link-text">24-May-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/May/31-May-2024" target="_blank">
              <div className="link-text">31-May-2024</div>
            </Link>
          </div>
        </div>
      </div>

      {/* April 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          April 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/April/19-April-2024" target="_blank">
              <div className="link-text">19-April-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/April/26-April-2024" target="_blank">
              <div className="link-text">26-April-2024</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletters;
